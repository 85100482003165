import type { CSSProperties, ReactElement } from "react";

import type { SelectChangeEvent } from "@mui/material";

import { AssessmentTypeEnum } from "@/apis/services/HazardService";
import { RCPScenarioEnum } from "@/apis/services/HazardService";
import {
  getOptions,
  getOptionsWithFallBack,
} from "@/components/asset/filter/filter-utils";
import { GenerateDropdown } from "@/components/common/dropdown/GenerateDropdown";
import {
  useRiskRatingsOpenSearchClimateScenarios,
  useRiskRatingsOpenSearchTimeHorizons,
} from "@/hooks/useRiskRatingsOpenSearch";

export type QueryValues = {
  assessmentType?: AssessmentTypeEnum;
  climateScenario?: RCPScenarioEnum | "all";
  timeHorizon?: number | "all";
};

interface Options {
  hideIcon?: boolean;
  styles?: CSSProperties;
  showLabels?: boolean;
  useOpenSearch?: boolean;
}

interface IRiskRCPScenarioTimeHorizonDropdownProps {
  queryValues: QueryValues;
  setQueryValues: (queryValues: QueryValues) => void;
  options: Options;
  children?: ReactElement;
}

export const RiskRCPScenarioTimeHorizonDropdown = (
  props: IRiskRCPScenarioTimeHorizonDropdownProps
) => {
  const { queryValues, setQueryValues, options, children } = props;
  const { hideIcon, styles, showLabels, useOpenSearch } = options;

  const { data: climateScenarios, isLoading: isClimateScenarioLoading } =
    useRiskRatingsOpenSearchClimateScenarios();

  const climateScenarioOptions = getOptions(
    Object.values(RCPScenarioEnum),
    useOpenSearch ? climateScenarios?.map((cs) => cs.key) : []
  );

  const { data: timeHorizons, isLoading: isTimeHorizonLoading } =
    useRiskRatingsOpenSearchTimeHorizons();

  const openSearchTimeHorizons = timeHorizons
    ?.map((th) => th.key)
    .filter((key) => !!key) as unknown as number[];

  const _timeHorizons = getOptionsWithFallBack(
    [2050, 2100],
    openSearchTimeHorizons,
    useOpenSearch
  );

  const timeHorizonOptions =
    queryValues.climateScenario !== "all" ? _timeHorizons : [];

  const updateQueryValues = (
    type: string,
    value?: RCPScenarioEnum | AssessmentTypeEnum | number
  ) => {
    const cs =
      type === "climate_scenario" ? value : queryValues.climateScenario;
    const th = type === "time_horizon" ? value : queryValues.timeHorizon;
    const at =
      cs === "all" ? AssessmentTypeEnum.CURRENT : AssessmentTypeEnum.FUTURE;

    setQueryValues({
      climateScenario: cs as RCPScenarioEnum,
      assessmentType: at as AssessmentTypeEnum,
      timeHorizon: cs === "all" ? "all" : (th as number),
    });
  };

  return (
    <>
      <GenerateDropdown
        value={queryValues.climateScenario}
        onChange={(e: SelectChangeEvent<RCPScenarioEnum | undefined>) =>
          updateQueryValues(
            "climate_scenario",
            e.target.value as RCPScenarioEnum
          )
        }
        options={climateScenarioOptions}
        label="Climate Scenario"
        styles={{ ...styles }}
        width={hideIcon ? "auto" : 190}
        hideIcon={hideIcon}
        showLabel={showLabels}
        allOptionsLabel="allClimateScenarios"
        isLoading={isClimateScenarioLoading}
      />
      {children}
      <GenerateDropdown
        value={queryValues.timeHorizon}
        onChange={(e: SelectChangeEvent<number | undefined>) =>
          updateQueryValues("time_horizon", e.target.value as number)
        }
        options={timeHorizonOptions}
        label="Time Horizon"
        allOptionsLabel="allTimeHorizons"
        styles={{ ...styles }}
        width={hideIcon ? "auto" : 165}
        hideIcon={hideIcon}
        showLabel={showLabels}
        isLoading={isTimeHorizonLoading}
      />
    </>
  );
};
