export const getAreaValue = (
  area: number = 0,
  precision: number = 2,
  format: boolean = true
) => {
  if (!format) return parseFloat(area.toFixed(precision));
  return Intl.NumberFormat("en", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: precision,
  }).format(Number(area));
};
