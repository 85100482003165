import { Stack, FormLabel } from "@mui/material";

import { InformativeTextPopover } from "@/components/common/InformativeTextPopover";

interface Props {
  label: string;
  text: string;
}

export const FilterHelperText = (props: Props) => {
  const { label, text } = props;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormLabel sx={{ color: "black !important", fontSize: 12 }}>
        {label}
      </FormLabel>
      <InformativeTextPopover text={text} isInfo hover fontSize={12} />
    </Stack>
  );
};
