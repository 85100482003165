import type { SelectChangeEvent } from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

import type { RiskRatingConsequenceType } from "@/apis/services/HazardService";
import { ConsequenceType } from "@/apis/services/HazardService";
import type { SettingNameEnum } from "@/apis/services/OrganizationService";
import {
  getOptionCount,
  getOptions,
} from "@/components/asset/filter/filter-utils";
import { ALL_CONSEQUENCES } from "@/constants/risks";
import { useConsequencesForSettings } from "@/hooks/useConsequences";
import { useRiskRatingsOpenSearchConsequences } from "@/hooks/useRiskRatingsOpenSearch";
import { getConsequenceDisplayLabel } from "@/utils/display-label-utils";

import { FilterHelperText } from "./FilterHelperText";

export type ConsequenceUnion =
  | RiskRatingConsequenceType
  | ConsequenceType
  | string;

interface Options {
  disabled?: boolean;
  showAll?: string;
  multiple?: boolean;
  label?: string;
  showHazardRating?: boolean;
  hideIcon?: boolean;
  dataTest?: string;
  useOpenSearch?: boolean;
}

interface FilterStyles {
  formStyles?: any;
  selectStyles?: any;
  itemStyles?: any;
}

interface Props<T> {
  value?: T[];
  onChange: (consequence: T[]) => void;
  setting: SettingNameEnum;
  options?: Options;
  styles?: FilterStyles;
  info?: { label: string; text: string };
}

export const ConsequenceFilter = (props: Props<ConsequenceUnion>) => {
  const { value, onChange, setting, options = {}, styles, info } = props;
  const { disabled, showAll, multiple, label } = options;
  const { showHazardRating, hideIcon, useOpenSearch } = options;

  const { data, isLoading, isError } = useConsequencesForSettings(setting);
  const { data: consequences, isLoading: isLoadingConsequences } =
    useRiskRatingsOpenSearchConsequences();

  const openSearchConsequences = consequences?.map((c) => c.key.toUpperCase());
  const _consequences = getOptions(
    data ?? [],
    useOpenSearch ? openSearchConsequences : []
  );

  const testId = options.dataTest ?? "consequence-filter-select";

  const handleChange = (event: SelectChangeEvent<Array<ConsequenceUnion>>) => {
    const {
      target: { value },
    } = event;

    onChange(
      typeof value === "string"
        ? (value.split(",") as ConsequenceUnion[])
        : (value as ConsequenceUnion[])
    );
  };

  return (
    <FormControl
      size="small"
      disabled={disabled}
      sx={{ minWidth: 140, maxWidth: 280, ...styles?.formStyles }}
    >
      {info && <FilterHelperText label={info.label} text={info.text} />}
      {label && <InputLabel id="Consequence">Consequence</InputLabel>}
      <Select
        label={label}
        labelId="Consequence"
        data-test={testId}
        value={value ?? ""}
        onChange={handleChange}
        multiple={multiple ?? false}
        displayEmpty
        color="primary"
        size="small"
        inputProps={hideIcon ? { IconComponent: () => null } : undefined}
        MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
        sx={{ ...styles?.selectStyles }}
      >
        {showAll && (
          <MenuItem
            key={ALL_CONSEQUENCES}
            value={ALL_CONSEQUENCES}
            sx={{ ...styles?.itemStyles }}
          >
            {showAll}
          </MenuItem>
        )}
        {(isLoading || isLoadingConsequences) && (
          <Typography>Loading...</Typography>
        )}
        {isError && <Typography>Error loading consequences</Typography>}
        {_consequences.map((consequence) =>
          showHazardRating || consequence !== ConsequenceType.HAZARD_RATING ? (
            <MenuItem
              key={consequence}
              value={consequence}
              data-test={`${testId}-option-${consequence}`}
              sx={{ ...styles?.itemStyles }}
              data-count={getOptionCount({
                option: consequence.toLowerCase(),
                useOpenSearch: useOpenSearch,
                openSearchOptions: consequences,
              })}
            >
              {getConsequenceDisplayLabel(consequence)}
            </MenuItem>
          ) : null
        )}
      </Select>
    </FormControl>
  );
};
