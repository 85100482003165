import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useServices } from "@/hooks/useServices";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

export const useOpenSearch = (searchKey: string, field: string) => {
  const { data: id } = useUserInfo();
  const { openSearch } = useServices();

  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const isOpenSearchEnabled = isFlagEnabled(flags, "useOpenSearch");

  const body = {
    size: 0,
    aggs: {
      [searchKey as string]: {
        terms: {
          field: field,
          size: 1000,
        },
      },
    },
  };

  return { openSearch, isOpenSearchEnabled, orgId, body };
};
