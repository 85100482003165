import React, { useState } from "react";

import { HelpOutline, Warning } from "@mui/icons-material";
import { Info } from "@mui/icons-material";
import { Popover, Typography, IconButton, Box } from "@mui/material";
import dynamic from "next/dynamic";

import { DisplayMultipleLineText } from "./DisplayMultipleLineText";

type InformativeTextProps = {
  text: string | object;
  hover?: boolean;
  multiline?: boolean;
  isJson?: boolean;
  color?: string;
  fontSize?: number;
  width?: string | number | undefined;
  isInfo?: boolean;
  isWarning?: boolean;
};

export const InformativeTextPopover = (props: InformativeTextProps) => {
  const { text, hover, multiline, isJson, color, fontSize, width } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const DynamicReactJson = dynamic(import("react-json-view"), { ssr: false });
  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = "informative-text-popover";

  return (
    <>
      <IconButton
        disableRipple={true}
        aria-describedby={id}
        onMouseEnter={hover ? openPopover : undefined}
        onMouseLeave={hover ? closePopover : undefined}
        onClick={openPopover}
        sx={{
          p: 0,
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent",
          },
        }}
      >
        {props.isInfo ? (
          <Info
            sx={{
              color: color === "red" ? "primary.main" : "primary.light",
              fontSize: fontSize ? fontSize : 18,
            }}
          />
        ) : props.isWarning ? (
          <Warning
            sx={{
              color: "#ff9800",
              fontSize: fontSize ? fontSize : 18,
            }}
          />
        ) : (
          <HelpOutline
            sx={{
              color: color === "red" ? "primary.main" : "primary.light",
              fontSize: fontSize ? fontSize : 18,
            }}
          />
        )}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            width: width || (text?.toString().length > 40 ? "15%" : undefined),
          },
        }}
        sx={{ pointerEvents: hover ? "none" : "inherit", maxHeight: "40vh" }}
        disableRestoreFocus={hover}
      >
        {isJson ? (
          <DynamicReactJson
            src={text as object}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            collapsed={true}
          />
        ) : multiline ? (
          <Box pl={2}>
            <DisplayMultipleLineText
              styles={{ fontSize: "small" }}
              text={text.toString()}
            />
          </Box>
        ) : (
          <Typography fontSize="small" padding={2}>
            {text as string}
          </Typography>
        )}
      </Popover>
    </>
  );
};
