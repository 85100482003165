import type { SelectChangeEvent } from "@mui/material";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";

import type { RiskRatingHazardsEnum } from "@/apis/services/HazardService";
import {
  getHazardOptions,
  getOptionCount,
} from "@/components/asset/filter/filter-utils";
import { AGGREGATE, ALL_HAZARDS } from "@/constants/risks";
import { useRiskRatingsOpenSearchSubHazardType } from "@/hooks/useRiskRatingsOpenSearch";
import { getHazardDisplayLabel } from "@/utils/display-label-utils";

import { FilterHelperText } from "./FilterHelperText";

export type HazardUnion = RiskRatingHazardsEnum | string;

interface Options {
  disabled?: boolean;
  showAll?: string;
  multiple?: boolean;
  label?: string;
  showAggregate?: boolean;
  hideIcon?: boolean;
  dataTest?: string;
  useOpenSearch?: boolean;
}

interface FilterStyles {
  formStyles?: any;
  selectStyles?: any;
  itemStyles?: any;
}

interface Props<T> {
  value?: T[];
  onChange: (hazard: T[]) => void;
  hazards?: { [key: string]: string[] };
  options?: Options;
  styles?: FilterStyles;
  info?: { label: string; text: string };
}

export const HazardFilter = (props: Props<HazardUnion>) => {
  const { value, onChange, hazards, options = {}, styles, info } = props;
  const { disabled, showAll, multiple, label } = options;
  const { showAggregate, hideIcon, useOpenSearch } = options;

  const { data: subHazards, isLoading } =
    useRiskRatingsOpenSearchSubHazardType();
  const openSearchHazards = subHazards?.map((hazard) => hazard.key);

  const _hazards = getHazardOptions(
    hazards,
    useOpenSearch ? openSearchHazards : []
  );

  const testId = options.dataTest ?? "hazard-filter-select";

  const handleChange = (event: SelectChangeEvent<HazardUnion[]>) => {
    const {
      target: { value },
    } = event;

    if (!multiple) {
      onChange([value as string]);
      return;
    }
    const selected =
      typeof value === "string"
        ? (value.split(",") as HazardUnion[])
        : (value as HazardUnion[]);

    if (showAll && selected.length > 1 && selected.includes(ALL_HAZARDS)) {
      (props.value ?? []).includes(ALL_HAZARDS)
        ? onChange(
            selected
              .sort((a, b) => {
                if (a === AGGREGATE) return -1;
                if (b === AGGREGATE) return 1;
                return 0;
              })
              .filter((o) => o !== ALL_HAZARDS)
          )
        : onChange([ALL_HAZARDS]);

      return;
    }
    // the aggegate option should always be the first in the list
    onChange(
      selected.sort((a, b) => {
        if (a === AGGREGATE) return -1;
        if (b === AGGREGATE) return 1;
        return 0;
      })
    );
  };

  if (isLoading) return <Skeleton />;
  return (
    <FormControl
      size="small"
      disabled={disabled}
      sx={{ minWidth: 100, maxWidth: 350, ...styles?.formStyles }}
    >
      {info && <FilterHelperText label={info.label} text={info.text} />}
      {label && <InputLabel id="Hazard">Hazard</InputLabel>}
      <Select
        label={label}
        labelId="Hazard"
        data-test={testId}
        value={value}
        onChange={handleChange}
        multiple={multiple ?? false}
        inputProps={hideIcon ? { IconComponent: () => null } : undefined}
        displayEmpty
        color="primary"
        size="small"
        MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
        sx={{ ...styles?.selectStyles }}
      >
        {showAll && (
          <MenuItem
            key={ALL_HAZARDS}
            value={ALL_HAZARDS}
            sx={{ ...styles?.itemStyles }}
          >
            {showAll}
          </MenuItem>
        )}

        {showAggregate && (
          <MenuItem
            key={AGGREGATE}
            value={AGGREGATE}
            sx={{ ...styles?.itemStyles }}
          >
            Multi-hazard risk (cumulative)
          </MenuItem>
        )}

        {_hazards &&
          Object.entries(_hazards).map(([key, value]) => [
            <ListSubheader style={styles?.itemStyles} key={key}>
              {key}
            </ListSubheader>,
            value.map((hazard) => (
              <MenuItem
                key={hazard}
                value={hazard}
                data-test={`${testId}-option-${hazard}`}
                data-count={getOptionCount({
                  option: hazard,
                  useOpenSearch: useOpenSearch,
                  openSearchOptions: subHazards,
                })}
                sx={{ ...styles?.itemStyles, mx: 2 }}
              >
                {getHazardDisplayLabel(hazard)}
              </MenuItem>
            )),
          ])}
      </Select>
    </FormControl>
  );
};
