import useSWR from "swr";

import type { SettingNameEnum } from "@/apis/services/OrganizationService";
import { THIRTY_MINUTES } from "@/constants/session";
import { useServices } from "@/hooks/useServices";

export const useConsequencesForSettings = (setting: SettingNameEnum) => {
  const { client } = useServices();
  const { data, isLoading, error } = useSWR(
    [`useConsequencesForSettings`, setting],
    async () => {
      const query = { setting: setting };
      const consequences = await client.organization.getConsequencesForSettings(
        query
      );
      return consequences.data;
    },
    {
      dedupingInterval: THIRTY_MINUTES,
    }
  );

  return {
    data,
    isLoading: isLoading,
    isError: error,
  };
};
