import useSWR from "swr";

import { OPEN_SEARCH_CACHE } from "@/constants/session";
import { useOpenSearch } from "@/hooks/useOpenSearch";

export const useRiskRatingsOpenSearchSubHazardType = () => {
  const { openSearch, isOpenSearchEnabled, orgId, body } = useOpenSearch(
    "hazard_types",
    "subhazard_type.keyword"
  );

  const { data, isLoading, error } = useSWR(
    isOpenSearchEnabled ? ["openSearchSubHazardType", orgId] : undefined,
    async () => {
      const subHazardTypes = await openSearch.risk_rating.getData(body, orgId);
      return subHazardTypes.data.aggregations.hazard_types.buckets;
    },
    { dedupingInterval: OPEN_SEARCH_CACHE }
  );

  return { data, isLoading, error };
};

export const useRiskRatingsOpenSearchConsequences = () => {
  const { openSearch, orgId, isOpenSearchEnabled, body } = useOpenSearch(
    "consequence_types",
    "consequence_type.keyword"
  );

  const { data, isLoading, error } = useSWR(
    isOpenSearchEnabled ? ["openSearchConsequences", orgId] : undefined,
    async () => {
      const consequences = await openSearch.risk_rating.getData(body, orgId);
      return consequences.data.aggregations.consequence_types.buckets;
    },
    { dedupingInterval: OPEN_SEARCH_CACHE }
  );

  return { data, isLoading, error };
};

export const useRiskRatingsOpenSearchClimateScenarios = () => {
  const { openSearch, orgId, isOpenSearchEnabled, body } = useOpenSearch(
    "climate_scenarios",
    "rcp_scenario.keyword"
  );

  const { data, isLoading, error } = useSWR(
    isOpenSearchEnabled ? ["openSearchClimateScenarios", orgId] : undefined,
    async () => {
      const climateScenarios = await openSearch.risk_rating.getData(
        body,
        orgId
      );
      return climateScenarios.data.aggregations.climate_scenarios.buckets;
    },
    { dedupingInterval: OPEN_SEARCH_CACHE }
  );

  return { data, isLoading, error };
};

export const useRiskRatingsOpenSearchTimeHorizons = () => {
  const { openSearch, orgId, isOpenSearchEnabled, body } = useOpenSearch(
    "time_horizon",
    "time_horizon"
  );

  const { data, isLoading, error } = useSWR(
    isOpenSearchEnabled ? ["openSearchTimeHorizon", orgId] : undefined,
    async () => {
      const timeHorizons = await openSearch.risk_rating.getData(body, orgId);
      return timeHorizons.data.aggregations.time_horizon.buckets;
    },
    { dedupingInterval: OPEN_SEARCH_CACHE }
  );

  return { data, isLoading, error };
};
