import { isEmptyObject } from "@/utils/utils";

export const getOptions = <T>(options: T[], openSearchOptions?: T[]) => {
  if (!openSearchOptions || openSearchOptions.length === 0) return options;
  return options.filter((option) => openSearchOptions.includes(option));
};

export const getHazardOptions = (
  hazards?: { [key: string]: string[] },
  filterList?: string[]
): { [key: string]: string[] } | undefined => {
  if (!hazards || isEmptyObject(hazards)) return undefined;
  if (!filterList || filterList.length === 0) return hazards;

  return Object.keys(hazards).reduce((acc, curr) => {
    const _hazards = hazards[curr].filter((hazard) =>
      filterList.includes(hazard)
    );
    if (!_hazards.length) return acc;
    return { ...acc, [curr]: _hazards };
  }, {});
};

export const getOptionsWithFallBack = (
  fallBackOptions: object,
  openSearchOptions?: object,
  useOpenSearch?: boolean
) => {
  if (useOpenSearch) return openSearchOptions ?? fallBackOptions;

  return fallBackOptions;
};

export const getOptionCount = (options: {
  option: string;
  useOpenSearch?: boolean;
  openSearchOptions?: {
    key: string;
    doc_count: number;
  }[];
}) => {
  const { option, useOpenSearch, openSearchOptions } = options;
  if (!useOpenSearch || !openSearchOptions) return "";

  const count = openSearchOptions.find((os) => os.key === option)?.doc_count;
  return `${count}`;
};
