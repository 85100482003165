import useSWR from "swr";

import type { AssetBasicResponse } from "@/apis/services/AssetService";
import type {
  RCPScenarioEnum,
  RatingInfo,
  RiskRatingBatchRequest,
  RiskRatingConsequenceType,
  RiskRatingHazardsEnum,
} from "@/apis/services/HazardService";
import {
  AssessmentTypeEnum,
  RiskRatingGrouping,
} from "@/apis/services/HazardService";
import { ReferenceTypeEnum } from "@/apis/services/HazardService";
import type { QueryValues } from "@/components/common/RiskRCPScenarioTimeHorizonDropdown";
import { isHazardAggregate } from "@/components/high-risks/exposure/risk-exposure-graph-utils";
import type { HighTideParams } from "@/components/high-risks/types";
import { AGGREGATE, ALL_CONSEQUENCES, ALL_HAZARDS } from "@/constants/risks";
import { createEnhancedAssetEntry } from "@/utils/assets/assets-utils";

import { useServices } from "./useServices";

const generateHighTideKey = (
  ids: string[],
  asset: AssetBasicResponse,
  sortedHighTideParams: any,
  query?: QueryValues
) => {
  return [
    "useGetAssetsRiskRatings",
    ids?.join(","),
    JSON.stringify(Object.keys(asset)),
    JSON.stringify(sortedHighTideParams),
    JSON.stringify(query),
  ];
};

export const useGetAssetsRiskRatings = (
  assets?: AssetBasicResponse[],
  highTideParams?: HighTideParams,
  query?: QueryValues
) => {
  const { hazard } = useServices();
  const _assets = assets ?? [];

  const queryValues = {
    assessment_type: query?.assessmentType,
    rcp_scenario: query?.climateScenario as RCPScenarioEnum,
    time_horizon: query?.timeHorizon as number,
  };
  const highTideQuery = {
    ref_type: ReferenceTypeEnum.ASSET,
    group_by: RiskRatingGrouping.CONSEQUENCE,
    ...queryValues,
  };

  const searchQuery: any = {
    ref_type: ReferenceTypeEnum.ASSET,
    ...queryValues,
  };
  const ref_ids = _assets.map((a) => a.id).sort();
  const filteredHazards =
    highTideParams?.hazards?.filter(
      (h) => h !== AGGREGATE && h !== ALL_HAZARDS
    ) ?? [];
  const sortedHighTideParams = {
    hazards: filteredHazards.sort() || [],
    consequences: highTideParams?.consequences?.sort() || [],
  };

  const { data, error, isLoading } = useSWR(
    _assets.length > 0
      ? generateHighTideKey(ref_ids, _assets[0], sortedHighTideParams, query)
      : null,
    async () => {
      // Risk Ratings come from two separate api calls: HighTide and Search
      const getHazardAggregate = isHazardAggregate(highTideParams?.hazards);

      const consequences =
        sortedHighTideParams.consequences.filter(
          (c) => c !== ALL_CONSEQUENCES
        ) ?? [];

      // if we want aggregated results, we need to use the highTide api
      // - only do this if the aggregate option is selected from the filter
      const aggregate = getHazardAggregate
        ? hazard.riskRatings.getHighTideRiskRating(highTideQuery, {
            ref_ids: ref_ids,
          })
        : Promise.resolve({ data: [] as RatingInfo[] });

      // if we want specific hazard / consequence combinations we need to use the search api
      // - we only want to do the api call if we have selected hazards (besides aggregate)
      const body: RiskRatingBatchRequest = {
        ref_ids: ref_ids,
        hazards: sortedHighTideParams.hazards as RiskRatingHazardsEnum[],
        consequences: (consequences as RiskRatingConsequenceType[]) ?? [],
      };
      const assetHazards =
        filteredHazards.length > 0
          ? hazard.riskRatings.searchRiskRating(searchQuery, body)
          : Promise.resolve({ data: [] as RatingInfo[] });

      const [highTideResult, searchResult] = await Promise.all([
        aggregate,
        assetHazards,
      ]);

      // combine the two data sets into one so we can assign the correct risks to the correct assets
      const risks = highTideResult.data.concat(searchResult.data);

      return risks;
    }
  );

  const _data = _assets.map((asset) => {
    return createEnhancedAssetEntry(asset, data, highTideParams);
  });

  return {
    riskRatings: data,
    data: _data,
    isLoading: isLoading,
    isError: error,
  };
};

export const useHighTideRiskRating = (assets: AssetBasicResponse[]) => {
  const { hazard } = useServices();
  const highTideQuery = {
    ref_type: ReferenceTypeEnum.ASSET,
    group_by: RiskRatingGrouping.CONSEQUENCE,
  };
  const ref_ids = assets.map((a: any) => a.id).sort();
  const { data, error, isLoading } = useSWR(
    assets?.length ? ["useHighTideRiskRating", ref_ids.join(",")] : [],
    async () => {
      const ratings = await hazard.riskRatings.getHighTideRiskRating(
        highTideQuery,
        { ref_ids }
      );

      return ratings.data;
    }
  );
  return {
    data: data,
    isLoading,
    isError: error,
  };
};

export const useFutureHighTideRiskRating = (
  assets: AssetBasicResponse[],
  query: {
    time_horizon?: number;
    rcp_scenario?: RCPScenarioEnum;
  }
) => {
  const { hazard } = useServices();
  const highTideQuery = {
    ref_type: ReferenceTypeEnum.ASSET,
    group_by: RiskRatingGrouping.CONSEQUENCE,
    assessment_type: AssessmentTypeEnum.FUTURE,
    ...query,
  };
  const ref_ids = assets.map((a: any) => a.id).sort();
  const { data, error, isLoading } = useSWR(
    assets?.length
      ? ["useHighTideRiskRating", ref_ids.join(","), query]
      : undefined,
    async () => {
      const ratings = await hazard.riskRatings.getHighTideRiskRating(
        highTideQuery,
        { ref_ids }
      );

      return ratings.data;
    }
  );
  return {
    data: data,
    isLoading,
    isError: error,
  };
};
