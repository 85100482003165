import { useState, useEffect } from "react";

import { ConsequenceType } from "@/apis/services/HazardService";
import type { HazardsEnum } from "@/apis/services/OrganizationService";
import type { ConsequenceUnion } from "@/components/asset/filter/ConsequenceFilter";
import { AGGREGATE } from "@/constants/risks";

type Props = {
  defaultHazards?: Array<HazardsEnum | string>;
  defaultConsequences: Array<ConsequenceUnion | string>;
};
export const useHazardsAndConsequences = (props?: Props) => {
  const defaultHazards = props?.defaultHazards || [AGGREGATE];
  const defaultConsequences = props?.defaultConsequences || [
    ConsequenceType.ECONOMIC_LOSS,
    ConsequenceType.DOWNTIME,
  ];

  const [{ selectedHazards, selectedConsequences }, setHazardsAndConsequences] =
    useState<{
      selectedHazards: {
        ui: Array<HazardsEnum | string>;
        swr: Array<HazardsEnum | string>;
      };
      selectedConsequences: {
        ui: Array<ConsequenceUnion | string>;
        swr: Array<ConsequenceUnion | string>;
      };
    }>({
      selectedHazards: { ui: defaultHazards, swr: defaultHazards },
      selectedConsequences: {
        ui: defaultConsequences,
        swr: defaultConsequences,
      },
    });

  const [timeoutID, setTimeoutID] = useState(null as any);

  useEffect(() => {
    if (timeoutID) clearTimeout(timeoutID);
    setTimeoutID(
      window.setTimeout(() => {
        setHazardsAndConsequences({
          selectedHazards: { ...selectedHazards, swr: selectedHazards.ui },
          selectedConsequences: {
            ...selectedConsequences,
            swr: selectedConsequences.ui,
          },
        });
      }, 700)
    );
  }, [selectedHazards.ui, selectedConsequences.ui]);

  return {
    selectedHazards: selectedHazards.swr,
    selectedConsequences: selectedConsequences.swr,
    selectedHazardsUi: selectedHazards.ui,
    selectedConsequencesUi: selectedConsequences.ui,
    setHazardsAndConsequences: ({
      hazards,
      consequences,
    }: {
      hazards?: Array<HazardsEnum | string>;
      consequences?: ConsequenceUnion[];
    }) => {
      return setHazardsAndConsequences({
        selectedHazards: hazards
          ? { swr: selectedHazards.swr, ui: hazards }
          : selectedHazards,
        selectedConsequences: consequences
          ? {
              swr: selectedConsequences.swr,
              ui: consequences,
            }
          : selectedConsequences,
      });
    },
  };
};
