import type { FocusEventHandler } from "react";

import type { SelectChangeEvent } from "@mui/material";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import type { CSSProperties } from "@mui/material/styles/createMixins";
import { useTranslation } from "react-i18next";

import { DefaultLoader } from "@/components/common/loading/DefaultLoader";

interface IGenerateDropDownProps {
  value?: string | number;
  onChange: (e: SelectChangeEvent<any>) => void;
  label?: string;
  options: object;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  width?: string | number;
  styles?: CSSProperties;
  showLabel?: boolean;
  isLoading?: boolean;
  hideIcon?: boolean;
  allOptionsLabel?: string;
  testId?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
}
export const GenerateDropdown = (props: IGenerateDropDownProps) => {
  const { value, onChange, label, options, onBlur, styles } = props;
  const { width, showLabel, isLoading, hideIcon, allOptionsLabel } = props;
  const { required, disabled, error } = props;
  const { t } = useTranslation();
  const testId = props.testId ?? "filter-select";

  if (isLoading)
    return <DefaultLoader sx={{ width: width ?? 140 }} height="25px" />;

  return (
    <FormControl size="small" sx={{ width: width ?? 140 }} required={required}>
      {showLabel && <InputLabel>{label}</InputLabel>}
      <Select
        disabled={disabled}
        required={required}
        label={(showLabel && label) ?? ""}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={hideIcon ? { IconComponent: () => null } : undefined}
        sx={styles}
        data-test={testId}
        error={error}
      >
        {allOptionsLabel && (
          <MenuItem value="all">{t(allOptionsLabel)}</MenuItem>
        )}

        {Object.values(options).map((o) => (
          <MenuItem
            key={o.key ?? o}
            value={o.key ?? o}
            data-test={`${testId}-option-${o.key ?? o}`}
          >
            <Stack
              direction="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              spacing={1}
            >
              <Typography>{t(o.key ?? o)}</Typography>
              {o.doc_count && (
                <Avatar
                  variant="rounded"
                  sx={{
                    bgcolor: "#b5b5b5",
                    width: 28,
                    height: 28,
                    fontSize: "0.8rem",
                    fontWeight: 800,
                  }}
                  data-test={`${testId}-option-${o.key}-count`}
                >
                  {o.doc_count}
                </Avatar>
              )}
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
