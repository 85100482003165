import useSWR, { mutate } from "swr";

import type { UpdateOrganizationSettingsRequest } from "@/apis/services/OrganizationService";
import { InstanceViewEnum } from "@/apis/services/OrganizationService";
import type { IndividualSettings } from "@/components/map/SettingsConfirmationPopUp";

import { useServices } from "./useServices";

export const useGetViewSettingsForGroup = (
  orgId?: string,
  groupId?: string
) => {
  const { client } = useServices();
  const { data, error } = useSWR(
    orgId && groupId ? [`useGetViewSettingsForGroup`, groupId] : null,
    async () => {
      const individualSettings =
        await client.organization.getIndividualViewSettings(orgId!, groupId!, {
          view: InstanceViewEnum.Group,
        });
      return individualSettings.data;
    }
  );

  return {
    data,
    isLoading: !error && !data,
    error,
  };
};

export const useCreateSettingsForView = () => {
  const { client } = useServices();
  const handleCreateSettingsForView = async (
    orgId: string,
    settings: IndividualSettings
  ) => {
    await client.organization.createIndividualViewSettings(
      orgId,
      settings.view_id!,
      { view: settings.view },
      { settings: { [settings.component]: settings.params } }
    );
    mutate(["useGetViewSettingsForGroup", settings.view_id]);
  };
  return handleCreateSettingsForView;
};

export const useUpdateSettingsForView = () => {
  const { client } = useServices();
  const handleUpdateSettingsForView = async (
    orgId: string,
    settings: IndividualSettings
  ) => {
    await client.organization.updateIndividualViewSettings(
      orgId,
      settings.view_id!,
      { view: settings.view },
      { settings: { [settings.component]: settings.params } }
    );
    mutate(["useGetViewSettingsForGroup", settings.view_id]);
  };
  return handleUpdateSettingsForView;
};

export const useUpdateSettingsForPortfolio = () => {
  const { client } = useServices();
  const handleUpdateSettingsForPortfolio = async (
    orgId: string,
    settings: UpdateOrganizationSettingsRequest
  ) => {
    await client.organization.updateOrganizationSettings(orgId, settings);
    mutate(["useGetOrgSettings", orgId]);
  };
  return handleUpdateSettingsForPortfolio;
};
