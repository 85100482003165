import type {
  AssetBasicResponse,
  BuildingUseEnum,
  OwnershipEnum,
} from "@/apis/services/AssetService";
import type { RiskRatingEnum } from "@/apis/services/HazardService";
import { RiskRatingHazardsEnum } from "@/apis/services/HazardService";
import type { ConsequenceUnion } from "@/components/asset/filter/ConsequenceFilter";
import type { HazardUnion } from "@/components/asset/filter/HazardFilter";
import { turnSnakeCaseIntoJoinedUpperCase } from "@/utils/string-utils";

export interface HighTideParams {
  hazards?: HazardUnion[];
  consequences?: ConsequenceUnion[];
}

export const CONSEQUENCE_TYPES: { [key: string]: string } = {
  FINANCIAL_LOSS: "Repair costs",
  ECONOMIC_LOSS: "Repair costs",
  REPAIR_COSTS: "Repair costs",
  INJURIES: "Injuries",
  FATALITIES: "Fatalities",
  FUNCTIONAL_RECOVERY: "Functional recovery",
  IMMEDIATE_OCCUPANCY: "Immediate occupancy",
  FULL_RECOVERY: "Full recovery",
  INVENTORY_LOSS: "Inventory loss",
  DOWNTIME: "Functional downtime",
  LIFE_SAFETY: "Individual life safety",
  AGGREGATE_LIFE_SAFETY: "Aggregate life safety",
  HEALTH_AND_WELLNESS: "Health & Wellness",
  DAMAGE: "Damage",
  SEVERE_WEATHER_WARNING: "Severe weather warning",
  HAZARD_RATING: "Hazard rating",
};

export const HAZARD_TYPES_BY_GROUP: { [key: string]: string } = {
  [RiskRatingHazardsEnum.GeophysicalSeismic]: "Geophysical",
  [RiskRatingHazardsEnum.GeophysicalLiquefaction]: "Geophysical",
  [RiskRatingHazardsEnum.GeophysicalTsunami]: "Geophysical",
  [RiskRatingHazardsEnum.GeophysicalLandslide]: "Geophysical",
  [RiskRatingHazardsEnum.GeophysicalVolcano]: "Geophysical",
  [RiskRatingHazardsEnum.GeophysicalAvalanche]: "Geophysical",
  [RiskRatingHazardsEnum.GeophysicalGroundSettlement]: "Geophysical",

  [RiskRatingHazardsEnum.HydrologicalFlooding]: "Hydrological",
  [RiskRatingHazardsEnum.HydrologicalStormwaterFlooding]: "Hydrological",
  [RiskRatingHazardsEnum.HydrologicalRiverineFlooding]: "Hydrological",
  [RiskRatingHazardsEnum.HydrologicalCoastalFloodingAndSeaLevelRise]:
    "Hydrological",
  [RiskRatingHazardsEnum.HydrologicalGroundwaterFlooding]: "Hydrological",

  [RiskRatingHazardsEnum.ClimatologicalExtremeHeat]: "Climatological",
  [RiskRatingHazardsEnum.ClimatologicalDrought]: "Climatological",
  [RiskRatingHazardsEnum.ClimatologicalWildfire]: "Climatological",
  [RiskRatingHazardsEnum.ClimatologicalWildfireSmoke]: "Climatological",
  [RiskRatingHazardsEnum.ClimatologicalExtremeCold]: "Climatological",

  [RiskRatingHazardsEnum.MeteorologicalIntegratedWind]: "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalCyclonicWind]: "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalThunderstormsLocalStorms]:
    "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalExtraTropicalDepressions]:
    "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalTornado]: "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalDustStorm]: "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalHail]: "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalSnow]: "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalIceStorm]: "Meteorological",
  [RiskRatingHazardsEnum.MeteorologicalLightning]: "Meteorological",

  [RiskRatingHazardsEnum.AdjacencyAirport]: "Adjacency",
  [RiskRatingHazardsEnum.AdjacencyHighway]: "Adjacency",
  [RiskRatingHazardsEnum.AdjacencyRailway]: "Adjacency",
  [RiskRatingHazardsEnum.AdjacencyPipeline]: "Adjacency",
  [RiskRatingHazardsEnum.AdjacencyIndustrialFacilities]: "Adjacency",
  [RiskRatingHazardsEnum.AdjacencyNuclear]: "Adjacency",
  [RiskRatingHazardsEnum.AdjacencyDams]: "Adjacency",
  [RiskRatingHazardsEnum.AdjacencyRoads]: "Adjacency",

  [RiskRatingHazardsEnum.TechnologicalStructuralIntegrity]: "Technological",
  [RiskRatingHazardsEnum.TechnologicalFire]: "Technological",

  [RiskRatingHazardsEnum.IntentionalTerrorism]: "Intentional",
  [RiskRatingHazardsEnum.IntentionalCriminality]: "Intentional",
  [RiskRatingHazardsEnum.IntentionalStateOnStateMilitaryAttack]: "Intentional",
};

export const CONSEQUENCES_TYPES_BY_GROUP: { [key: string]: string } = {
  economic_loss: "Asset",
  inventory_loss: "Asset",
  downtime: "Asset",
  life_safety: "Asset",
  aggregate_life_safety: "Asset",
  health_and_wellness: "Asset",
  damage: "Asset",
  severe_weather_warning: "Asset",
  hazard_rating: "Asset",
  siteaccess_roads: "Site Access",
  siteaccess_bridges: "Site Access",
  siteaccess_rail: "Site Access",
  utility_power: "Utilities",
  utility_water: "Utilities",
  utility_waste: "Utilities",
  utility_telecom: "Utilities",
  utility_fiber: "Utilities",
};

export const getHazardType = (hazard: string) => {
  return RiskRatingHazardsEnum[
    turnSnakeCaseIntoJoinedUpperCase(
      hazard
    ) as keyof typeof RiskRatingHazardsEnum
  ];
};

export type AssetBasicResponseFlattened = Omit<
  AssetBasicResponse,
  "ownership" | "primary_use" | "business_group"
> & {
  ownership: OwnershipEnum | "Unassigned";
  primary_use: BuildingUseEnum | "Unassigned";
  business_group: string;
} & { monthly_revenue?: number; asset_count: number; iris_group?: string };

export type ScaledRiskRatingCount = {
  [key in RiskRatingEnum]: number;
};

export type RiskExposureChartData = {
  [key: string]: string;
} & ScaledRiskRatingCount;

export enum ScalingMetricEnum {
  AssetCount = "asset_count",
  Population = "total_building_population",
  SurfaceArea = "occ_area",
  TotalValue = "gross_asset_value",
  ReplacementValue = "replacement_cost",
  MonthlyRevenue = "monthly_revenue",
}

export enum ClassificationMetricEnum {
  Ownership = "ownership",
  PrimaryUse = "primary_use",
  BusinessGroup = "business_group",
  IrisGroup = "iris_group",
  Hazard = "hazard",
}

export type ScalingMetricType = {
  [Key in ScalingMetricEnum]?: {
    title: string;
    icon?: string;
  };
};

export type ClassificationMetricType = {
  [K in ClassificationMetricEnum]?: {
    title: string;
    icon?: string;
  };
};
