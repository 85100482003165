import { ConsequenceType } from "@/apis/services/HazardService";
import { AllHazardsEnum } from "@/apis/services/OrganizationService";
import {
  HAZARD_TYPES_BY_GROUP,
  CONSEQUENCES_TYPES_BY_GROUP,
} from "@/components/high-risks/types";
import { classOneRatingColors } from "@/components/risk-ratings/ClassOneRiskRatings";
import { onlyUnique } from "@/utils/array-utils";

//find the asset which has the highest height
export const findTotalPixel = (o: Object) => {
  return Object.entries(o).reduce((agg, [key, value]) => {
    if (
      key !== "groupName" &&
      key !== "groupNickname" &&
      key !== "assetName" &&
      key !== "assetNickname" &&
      !key.includes("Color") &&
      !key.includes("Hazard")
    )
      return agg + value;
    else return agg;
  }, 0);
};

//sort assets graphs in the bargraph
export const sortOnRating = (asc: boolean) => {
  return (a: Object, b: Object) => {
    const aTot = findTotalPixel(a);
    const bTot = findTotalPixel(b);

    if (asc) return bTot - aTot;
    return aTot - bTot;
  };
};

export const sortOnName = () => {
  return (a: { assetName: string }, b: { assetName: string }) =>
    a.assetName < b.assetName ? -1 : 1;
};

//Find the risk level lable for the tooltip
export const findRiskLevel = (rating: number, consequence: ConsequenceType) => {
  for (const [key, value] of Object.entries(classOneRatingColors)) {
    const pixelLookUp =
      consequence === ConsequenceType.DOWNTIME ? "exponentialPixel" : "pixel";
    if (value[pixelLookUp] === rating) return key;
  }
  return "";
};

export const filterByKey = (arr: Array<any>, key: string) => {
  return arr
    .map((a) => {
      return a[key];
    })
    .filter(onlyUnique);
};

export const linearGradientDef = (id: string, colors: any[], options = {}) => ({
  id,
  type: "linearGradient",
  colors,
  ...options,
});

//Apply opacity to the subgraphs do not represent the selected hazard type
export const findUnselectedHazards = (arr: Array<string>, selected: string) => {
  return arr
    .filter((a) => selected !== AllHazardsEnum.AllHazards && selected !== a)
    .map((a) => {
      return {
        match: (props: any) => props.data.data[`${props.data.id}Hazard`] === a,
        id: "gradientA",
      };
    });
};

export const prepareListByGroup = (list: Array<string>, hazard: boolean) => {
  const groupList: { [key: string]: Array<string> } = {};
  const GROUPS = hazard
    ? { ...HAZARD_TYPES_BY_GROUP }
    : { ...CONSEQUENCES_TYPES_BY_GROUP };
  list.forEach((item) => {
    if (groupList.hasOwnProperty(GROUPS[item])) {
      groupList[GROUPS[item]].push(item);
    } else {
      const array = [item];
      groupList[GROUPS[item]] = array;
    }
  });
  return groupList;
};

export const calculateWidth = (
  dataAmount: number,
  isAssetRiskCountTable?: boolean
) => {
  let width = 50;
  if (dataAmount === 1) return isAssetRiskCountTable ? 135 : 165;
  switch (Math.floor(dataAmount / 3)) {
    case 0:
      width += dataAmount * (isAssetRiskCountTable ? 55 : 70);
      break;
    case 1:
      width += dataAmount * (isAssetRiskCountTable ? 40 : 55);
      break;
    case 2:
      width += dataAmount * (isAssetRiskCountTable ? 35 : 45);
      break;
    case 3:
      width += dataAmount * (isAssetRiskCountTable ? 33 : 40);
      break;
    default:
      width += dataAmount * 30;
      break;
  }
  return width;
};
